@import "normalize.less";
@import "lesshat.less";

@pageWidth: 720px;
@highlightLight: #484c48;
@highlightDark: #000000;

* {
	margin: 0;
	padding: 0;
}

/*------------------------------------*\
  RESET
\*------------------------------------*/
body, div, dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6,
pre, form, fieldset, input, textarea,
p, blockquote, th, td { 
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset, img {
  border: 0;
}
address, caption, cite, dfn, th, var {
  font-style: normal;
  font-weight: normal;
}
caption, th {
  text-align: left;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before, q:after {
  content: '';
}
abbr, acronym {
  border: 0;
}



.clear {
	clear: both;
}


.clearfix:after {	/* für Firefox, IE8, Opera, Safari, etc. */
	content: ".";  
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
 
* + html .clearfix {	/* für IE7 */
	display: inline-block;
}
 
* html .clearfix {	/* für IE6 */
	height: 1%;
}


@magicNo: 16px * 1.5;


/*.transition(@property, @duration, @timingFunction, @delay: 0s) {
	-webkit-transition: @arguments;
	-moz-transition: @arguments;
	-ms-transition: @arguments;
	-o-transition: @arguments;
	transition: @arguments;
}*/

.transition(@args) {
	-webkit-transition: @args;
	-moz-transition: @args;
	-ms-transition: @args;
	-o-transition: @args;
	transition: @args;
}


body {
	background: #e5efe5;
	
	font-family: 'Oxygen', Arial, Helvetica, sans-serif;
	//font-size: 14px;
	font-size: 1em;
	font-weight: normal;
	color: #333833;
	//line-height: 1.5em;
	line-height: @magicNo;
}



#main {
	p {
		//margin-bottom: @magicNo;
		margin-bottom: 16px;
	}
	/*p+p {
		text-indent: 2em;
		margin-top: -@magicNo;
	}*/


	h1, h2, h3, h4, h5, h6 {
		font-family: Coda, 'Open Sans Condensed', 'Open Sans', Verdana, sans-serif;
		font-weight: normal;
		margin: 0px;
		line-height: 1.3em;
		margin-bottom: 0.2em;
		//letter-spacing: 0.03em;
	}
	h1 {
		font-size: 4.5em;
		line-height: 1.0em;
		margin-bottom: 14.2px;
		font-variant: small-caps;
	}
	h2 {
		font-size: 3.5em;
		line-height: 1.0em;
		margin-bottom: 11.2px;
		font-variant: small-caps;
	}
	h3 {
		font-size: 2.5em;
		line-height: 1.2em;
		margin-bottom: 8px;
		font-variant: small-caps;
	}
	h4 {
		font-size: 2em;
		margin-bottom: 6.4px;
		//font-variant: small-caps;
	}
	h5 {
		font-size: 1.4em;
		margin-bottom: 4.48px;
		//font-variant: small-caps;
	}
	h6 {
		font-size: 1em;
		margin-bottom: 3.2px;
		//font-variant: small-caps;
	}
}


.breadcrumbs {
	display: none;
}
.breadcrumbs {
	margin-left: -2px;
	a {
		padding: 0px 2px;
	}
	.active {
		text-decoration: underline;
	}
}

#main img {
	float: left;
	//margin: 0px 10px 10px 0px;
	box-shadow: 1px 1px 4px #555555;
	margin: 7px 0px 16px 0px;
}

#site {
	.inside {
		width: @pageWidth;
		/////margin: 0px auto;
		margin: 0px 0px 0px 100px;
	}

	#header {
		border: 1px solid @highlightDark;
		border-left: none;
		border-right: none;
		border-bottom: none;

		height: 133px + 52px;

		.highlight {
			border: 1px solid @highlightLight;
			//border-bottom: 1px solid #2f312f;
			border-left: none;
			border-right: none;

			height: 131px;

			background: #333833; /* Old browsers */
			background: -moz-linear-gradient(top,  #333833 0%, #171a17 100%); /* FF3.6+ */
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#333833), color-stop(100%,#171a17)); /* Chrome,Safari4+ */
			background: -webkit-linear-gradient(top,  #333833 0%,#171a17 100%); /* Chrome10+,Safari5.1+ */
			background: -o-linear-gradient(top,  #333833 0%,#171a17 100%); /* Opera 11.10+ */
			background: -ms-linear-gradient(top,  #333833 0%,#171a17 100%); /* IE10+ */
			background: linear-gradient(to bottom,  #333833 0%,#171a17 100%); /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333833', endColorstr='#171a17',GradientType=0 ); /* IE6-9 */

			z-index: 100;

		}

		.inside {
			position: relative;

			h1 {
				position: absolute;
				top: 25px;
				left: 0px;
				width: 500px;
				height: 86px;
				background: url('../img/fendtfanclassiclogo.png') top left no-repeat;

				span {
					visibility: hidden;
				}
			}

			.annotation {
				float: right;
				text-align: right;
				margin-top: 45px;
				line-height: 20px;
				//font-family: Verdana, Arial, sans-serif;
				font-family: Coda, Oxygen, Verdana, Arial, sans-serif;
				font-size: 18px;
				font-weight: normal;
				letter-spacing: 0px;
				text-decoration: none;
				color: #bac5ba;

				text-shadow: 1px 1px 1px #000000;

				span {
					display: block;
					font-weight: normal;
					font-size: 14px;
					line-height: 14px;
				}
			}


			.login {
				position: absolute;

				top: -2px;
				right: -@magicNo;

				width: auto;
				//width: 250px;

				z-index: 9999;

				background: none;

				padding: @magicNo;
				padding-top: 4px;

				text-align: right;
				font-family: Coda, Oxygen, Verdana, Arial, sans-serif;
				font-family: 'Open Sans', Oxygen, Verdana, Arial, sans-serif;
				font-weight: 400;
				font-size: 14px;
				letter-spacing: 0px;

				color: #bac5ba;
				a {
					color: #bac5ba;
				}
				a:hover {
					//font-weight: normal;
					text-decoration: underline;
				}

				text-shadow: 1px 1px 1px #000000;

				.border-radius(0px 0px 4px 4px);

				.login-form {
					display: none;

					p {
						margin: 2px 0px;
					}

					input, button {
						width: 100%;

						margin: 0px;
					}
					input[type="submit"], input[type="button"], button {
						margin-top: 4px;
					}

					//input {
					//	width: 200px;
					//	border: 1px solid #EEEEEE;
					//	margin: 1px 0px;
					//	padding: 2px 6px;
					//	-webkit-border-radius: 4px;
					//	-moz-border-radius: 4px;
					//	border-radius: 4px;
					//
					//	font-family: inherit;
					//	font-size: 1.2em;
					//
					//	background: rgb(227,231,232); /* Old browsers */
					//	background: -moz-linear-gradient(top,  rgba(227,231,232,1) 0%, rgba(222,226,226,1) 100%); /* FF3.6+ */
					//	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(227,231,232,1)), color-stop(100%,rgba(222,226,226,1))); /* Chrome,Safari4+ */
					//	background: -webkit-linear-gradient(top,  rgba(227,231,232,1) 0%,rgba(222,226,226,1) 100%); /* Chrome10+,Safari5.1+ */
					//	background: -o-linear-gradient(top,  rgba(227,231,232,1) 0%,rgba(222,226,226,1) 100%); /* Opera 11.10+ */
					//	background: -ms-linear-gradient(top,  rgba(227,231,232,1) 0%,rgba(222,226,226,1) 100%); /* IE10+ */
					//	background: linear-gradient(to bottom,  rgba(227,231,232,1) 0%,rgba(222,226,226,1) 100%); /* W3C */
					//	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e3e7e8', endColorstr='#dee2e2',GradientType=0 ); /* IE6-9 */
					//	
					//}
					//input[type=submit], input[type=button] {
					//	width: 214px;
					//}

					form#login-header {
						margin: 6px 0px @magicNo 0px;
					}
				}
			}
			.login.opened {
				background-color: #111111;

				.box-shadow(~"rgba(0,0,0, 0.5) 0px 2px 4px");

				a {
					//font-weight: bold;
				}
			}
		}

		/*position: relative;
	
		&:before {
			position: absolute;
			display: block;
			content: '';
			border: 1px solid @highlightLight;
			height: 100%;
			width: 100%;
			box-sizing: border-box;
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
		}​*/


		position: relative;

		#ffcCart {
			display: block;
			position: fixed;

			top: 240px;
			top: 30px;
			left: 100px;
			background: #EEEEEE url('../img/wmb/32/573.png') 8px 5px no-repeat;
			border: 1px solid #DDDDDD;

			.box-shadow(~"rgba(0,0,0, 0.15) 2px 2px 4px");

			margin-left: 50px;
			padding: 8px;

			z-index: 1000;

			.border-radius(4px);

			.container {
				display: none;
			}

			.opener {
				cursor: pointer;
				font-size: 1.3em;
				font-weight: bold;
				background: url('../img/wmb/32/995.png') top right no-repeat;
				padding-right: 40px;

				padding-left: 32px;

				border-bottom: 2px solid #DDDDDD;
				margin-bottom: 0px;
				padding-bottom: 12px;

				&.opened {
					background: none;
					&:hover {
						background: url('../img/wmb/32/994.png') top right no-repeat;
					}
				}
			}

			.content {
				overflow-y: scroll;

				font-size: 0.85em;

				.components {
					table.table-components {
						margin-top: 4px;
					}
					table.table-kits {
						margin-top: 12px;
					}
					table tbody tr {
						td:first-child {
							letter-spacing: 0px;
						}
						td:nth-child(2) {
							letter-spacing: 1px;
						}
						td:nth-child(4) {
							letter-spacing: 0px;
						}

						td.component-cart-actions, td.kit-cart-actions {
							white-space: nowrap;
							div {
								width: 25px;
							}
						}

						img {
							opacity: 0.1;
						}

						&:hover {
							img {
								opacity: 1.0;
							}
						}
					}
				}
			}

			.controls {
				display: none;

				border-top: 2px solid #DDDDDD;
				margin-top: 0px;
				padding-top: 12px;

				min-width: 380px;

				button {
					padding-left: 8px;
					padding-right: 8px;
					//width: 100%;
				}

				.reset {
					float: right;
				}
			}
		}
	}

	#navigation {
		border-top: 1px solid @highlightDark;
		height: 52px;
		background: url('../img/navigationbg.png') top left repeat-x;

		margin-top: 132px;

		z-index: 200;

		.inside {

			overflow: visible;

			ul {
				display: table;
				width: 100%;
				table-layout: fixed;

				//margin-top: -2px;

				height: 54px;

				li {
					display: table-cell;
					text-align: center;
					position: relative;

					vertical-align: top;

					a {
						position: relative;
						top: 0px;
						left: 0px;
						z-index: 300;

						margin: 0px 1px;

						line-height: 50px;	// 54px
						display: block;
						text-decoration: none;

						font-family: 'Open Sans', Verdana, sans-serif;
						font-size: 18px;
						color: #e5efe5;
						font-weight: 300;

						text-shadow: 1px 1px 0px #000000;

						border: 1px solid rgba(0, 0, 0, 0.0);
						border-top: none;

						//transition: all 0.1s ease-in 0s;

						&.active, &:hover {
							border: 1px solid #000000;
							border-top: none;

							//margin-top: -20px;
							//margin-bottom: -1px;

							//top: -2px;

							background: #171a17; /* Old browsers */
							background: -moz-linear-gradient(top,  #171a17 0%, #0a0c0a 100%); /* FF3.6+ */
							background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#171a17), color-stop(100%,#0a0c0a)); /* Chrome,Safari4+ */
							background: -webkit-linear-gradient(top,  #171a17 0%,#0a0c0a 100%); /* Chrome10+,Safari5.1+ */
							background: -o-linear-gradient(top,  #171a17 0%,#0a0c0a 100%); /* Opera 11.10+ */
							background: -ms-linear-gradient(top,  #171a17 0%,#0a0c0a 100%); /* IE10+ */
							background: linear-gradient(to bottom,  #171a17 0%,#0a0c0a 100%); /* W3C */
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171a17', endColorstr='#0a0c0a',GradientType=0 ); /* IE6-9 */

							//transition: all 0.1s ease-in 0s;
						}
						&.active {
							top: 0px;
							line-height: 54px;
						}
						&.active:after {
							content: "";
							height: 8px;
							width: 25px;
							position: absolute;
							top: 55px;	// or 55px? black line
							left: 50%;
							margin-left: -12px;
							background: url('../img/navigationtriangle.png') no-repeat 0 0;

							z-index: 500;
						}
					}
				}
			}
		}
	}

	#main {
		background: url('../img/topbg.png') top left repeat-x;

		min-height: 500px;

		#content {
			padding: @magicNo*2 0px 32px 0px;
		//}

			.search-bar {
				position: relative;
				width: 100%;

				margin-bottom: @magicNo;

				input {
					box-sizing: border-box;
					width: 100%;

					padding-left: 36px;
				}

				.search-bar-button {
					position: absolute;
					left: 0;
					top: 0;

					background: none;
					border: none;
					padding: 8px 7px 10px 10px;
					margin: 0;

					//font-size: 20px;
					color: #000000;
					text-shadow: none;

					box-shadow: none;

					cursor: pointer;
					opacity: 0.25;
					transition: 0.2s opacity ease;

					.fa {
						display: block;
					}

					&:hover, &:active {
						opacity: 0.6;
					}
					&:focus {
						outline: none;
					}
				}
			}

			.search-results {
				.search-limit-hint {
					font-size: 0.8125em;
				}

				table {
					margin-bottom: @magicNo;
					width: 100%;

					td, th, .action a {
						padding: 6px 12px;
					}
					.action {
						padding: 0;

						a {
							display: block;
						}
						a, a:hover, a:active {
							text-decoration: none;
						}

						.fa {
							display: block;
						}
					}

					th {
						font-weight: bold;
					}

					.search-result-item {
						td {
							vertical-align: top;

							&.k-identifier, &.k-name, &.action {
								border-top: 1px dashed rgba(0,0,0, 0.1);
							}

							&.action {
								vertical-align: middle;
							}
						}
						&.next {
							td {
								border-top: 1px solid #000000;
							}
						}
						&.odd {
							background-color: rgba(255, 255, 255, 0.45);
						}
					}

					.c-identifier {
						font-weight: bold;
					}
				}
			}

			.page-kit {
				.kit-img {
					/*width: 300px;
					height: 300px;*/
					box-shadow: 1px 1px 4px #777777;
					margin-right: 1em !important;
				}

				.manufacturers, .components {
					margin-bottom: 48px;
				}

				.manufacturers {
					display: none;

					float: right;

					.manufacturer-box {
						padding: 14px 11px;
						box-shadow: 1px 1px 4px #AAAAAA;
						background: rgb(249,249,249); /* Old browsers */
						background: -moz-linear-gradient(top,  rgb(249,249,249) 0%, rgb(237,237,237) 100%); /* FF3.6+ */
						background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(249,249,249)), color-stop(100%,rgb(237,237,237))); /* Chrome,Safari4+ */
						background: -webkit-linear-gradient(top,  rgb(249,249,249) 0%,rgb(237,237,237) 100%); /* Chrome10+,Safari5.1+ */
						background: -o-linear-gradient(top,  rgb(249,249,249) 0%,rgb(237,237,237) 100%); /* Opera 11.10+ */
						background: -ms-linear-gradient(top,  rgb(249,249,249) 0%,rgb(237,237,237) 100%); /* IE10+ */
						background: linear-gradient(to bottom,  rgb(249,249,249) 0%,rgb(237,237,237) 100%); /* W3C */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ededed',GradientType=0 ); /* IE6-9 */
					}




					h6 {
						font-weight: bold !important;
					}

					ul {
						list-style-type: none;
						margin-right: -8px;

						li {
							float: left;
							margin-right: 8px;

							h6 {
								//font-size: 1.3em;
								//font-variant: small-caps;
								text-transform: uppercase;
								//text-decoration: underline;
								border-bottom: 1px solid #eaeaea;
								margin-bottom: @magicNo*0.333 !important;
							}

							ul {
								font-size: 1.1em;
								line-height: 1.1em;
								//color: #333333;
								opacity: 0.75;

								li {
									float: none;
									margin-left: 0px;
								}
							}
						}
					}
				}


				button.add-kit-to-cart {
					//text-align: right;
					margin: 16px 0px;
					//padding: 5px 8px;
				}

				.components {
					// siehe extra Abschnitt

					/** OLD end **/

					ul {
						display: none;

						list-style-position: inside;
						list-style-type: circle;

						font-size: 1.0em;
						line-height: 1.15em;
						font-weight: normal;

						li {
							margin: 2px 0px 2px 8px;

						}
					}
				}

				.images {
					h3 {

					}

					ul {
						list-style-type: none;
						margin: 0px;
						padding: 0px;

						margin-left: -30px;

						li {
							margin-left: 30px;

							float: left;

							img {
								width: 220px;
								height: 165px;
								float: none;
								margin: 0px 0px 24px 0px !important;
							}
						}
					}
				}
			}

		}
	}


	#footer {
		border-top: 2px solid #000000;

		color: #aab5aa;
		text-shadow: 1px 1px 1px #000000;

		font-size: 0.9em;
		line-height: 1.5em;

		.highlight {
			border-top: 1px solid #383b38;
			height: 264px;

			background: url('../img/footerbg.png') top left repeat;

			.inside {
				position: relative;

				#websites {
					position: absolute;
					top: 48px;
					left: 42px;

					text-align: center;

					font-size: 18px;
					line-height: 1.9em;

					p {
						padding: 20px 0px 0px 0px;
					}

					a {
						color: inherit;
					}
				}

				#callback {
					/*.icon-callback {
						width: 64px;
						height: 64px;
						margin: 0px 0px 20px 55px;
						float: left;
					}*/
					position: absolute;
					top: 0px;
					left: 250px;

					text-align: left;

					margin-left: 0px;
					padding-left: 48px;

					background: url('../img/phone.png') -15px 48px no-repeat;

					h2 {
						font-size: 24px;
						line-height: 2em;
						margin-left: -48px;
					}


					form {
						width: 240px - 10px - 48px;
					}

					input[type="text"],input[type="tel"] {
						.background-image(~"linear-gradient(180deg, rgba(0,0,0, 0.7) 0%, rgba(0,0,0, 0.5) 90%), url('../img/inputbg.png')");
						color: #dfdfdf;
						text-shadow: rgba(0,0,0, 0.8) 0px 1px 0px;
						margin-bottom: 5px;
					}

					input[type=submit] {
						margin: 7px 0px 0px 0px;
						font-weight: 400;
						text-shadow: rgba(0,0,0, 1.0) 0px -1px 0px;
						color: #cacaca;
					}



					/*input {
						border: none;
						background: -moz-linear-gradient(center top , #3E4348 0%, #43484E 100%) repeat scroll 0 0 transparent;
						border-bottom: 1px solid #4F5359;
						border-radius: 3px 3px 3px 3px;
						border-top: 1px solid #2B2E32;
						color: #BDC8CE;
						margin-bottom: 8px;
						padding: 5px 10px;
						text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
					}
					input:focus {
						background: -moz-linear-gradient(center top , #484D52 0%, #4C5156 100%) repeat scroll 0 0 transparent;
						border-bottom: 1px solid #575B61;
						color: white;
					}

					input[type=submit] {
						background: -moz-linear-gradient(center top , #0C8DA9 0%, #056C8C 100%) repeat scroll 0 0 transparent !important;
					    border-color: #0C829C #056C8B #16738F !important;
					    border-radius: 3px 3px 3px 3px;
					    border-style: solid !important;
					    border-width: 1px !important;
					    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
					    color: #D2F5FF !important;
					    display: inline-block;
					    font-size: 1em;
					    line-height: 19px;
					    margin-bottom: 20px;
					    margin-top: 8px;
					    padding: 5px 15px !important;
					    position: relative;
					    text-decoration: none;
					    text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.7);
					    //width: auto !important;
					}*/
				}

				#contact {
					position: absolute;
					top: 56px;
					left: 555px;

					text-align: center;
				}
			}


			position: relative;

			.bottombar {
				height: 40px;
				background-color: rgba(0, 0, 0, 0.2);
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;

				.inside {
					line-height: 38px;
					text-align: center;
					color: #666666;
				}
			}
		}
	}
}


.link-list {
	a, a:link {
		display: inline-block;
		padding: 1.0em 1.5em;
		color: #e5efe5 !important;
		background: url('../img/footerbg.png') top left repeat;
	}
}



a, a:link, a:active, a:visited {
	text-decoration: none;
	color: #354237;

	img {
		vertical-align: -2px;
		margin-right: 3px;
	}
}
a:hover {
	text-decoration: underline;
	//color: #669966;
}


.m-fendt {
	color: #47773D;
}
.m-deutz {
	color: #57B658;
}
.m-schlueter {
	color: #BB1A12;
}



/* qTip ! */
.tooltip-callback {
	font-size: 0.95em !important;
	line-height: 1.5em;
}




.caption-image {
	// imported from #main img
	//float: left;
	//margin: 0px 10px 10px 0px;
	box-shadow: 1px 1px 4px #555555;
	margin: 7px 0px 16px 0px;
	margin: 0px;

	

	position: relative;

	

	.caption {
		position: absolute;
		bottom: 0px;
		display: block;
		background-color: rgba(0, 0, 0, 0.7);
		.transition(background-color 0.2s ease-out);
		width: 100%;

		font-size: 1em;
		line-height: 1.4em;
		padding: 13px 0px;
		color: #FFFFFF;
		font-weight: bold;

		//font-family: Coda;
		//font-weight: normal;
		//font-family: 'Open Sans',Verdana,sans-serif;
		//font-size: 18px;
		//font-weight: 300;

		letter-spacing: 0.9px;

		text-shadow: 1px 1px 1px #000000;

		text-align: right;

		span {
			display: block;
			margin: 0px 18px;
		}
	}

	img {
		box-shadow: none !important;
		margin: 0px !important;
	}

	a:hover, a:active {
		.caption {
			//font-weight: bold;
			background-color: rgba(0, 0, 0, 0.8);
		}
	}
}





#categoryListing {
	////margin-top: -32px;

	ul {
		list-style-type: none;

		//margin-top: 16px;
		margin-top: -6px;

		li {
			////margin-top: 32px;
			margin-bottom: 48px;

			float: none;

			.caption-image {
				//width: 400px;
				margin-top: 6px;
				float: left;

				font-size: 1.6em;

				img {
					width: 400px;
					//float: none !important;
					
					//margin-right: @magicNo;
				}
			}
			ul {
				margin-left: 440px;

				list-style-type: square;
				list-style-position: outside;

				font-size: 1.20em;
				line-height: 1.4em;


				li {
					margin: 0px;

					.numKits {
						color: #999999;
						padding: 0px 6px;
						font-size: 0.7em;
						vertical-align: bottom;
					}
				}
			}
		}
	}
}

#categoryList {
	ul {
		list-style-type: none;

		width: 760px;

		li {
			margin-bottom: 2em;

			float: left;
			width: 380px;

			.caption-image {
				width: 340px;	// 320px
				height: 228px;	// 215px
				overflow: hidden;

				padding: 0px 0px 10px 0px;
				background-color: #FFFFFF;

				img {
					//float: none !important;

					width: 340px;
				}
			}
		}
	}
}

#kitList {
	ul {
		list-style-type: none;

		width: 720px;

		li {
			margin-bottom: 2em;

			.caption-image {
				font-size: 1.4em;

				span.num-components {
					//float: left;
					font-size: 16px;
					font-weight: normal;
					letter-spacing: 0px;
					//vertical-align: bottom;
					margin: 0px;
					position: absolute;
					//left: 15px;
					//bottom: 13px;
					right: 15px;
					top: 10px;

					left: 15px;

					.transition(opacity 0.2s ease-out);
					opacity: 0.7;

					color: #333833;
					text-shadow: 1px 1px 1px #AAAAAA;
				}

				img {
					width: 720px;
					float: none !important;
				}

				a:hover, a:active {
					.num-components {
						opacity: 1.0;
					}
				}
			}

			p {
				//margin-left: 424px;
				margin-top: 16px;
			}
		}
	}
}



.kit-detailed {
	table {
		width: 100%;

		tr {
			td.components {
				font-size: 12px;

				table {
					margin-right: 15px;
					td {
						//padding: 0px 3px;
						padding: 2px 3px;
						line-height: 1.5em;
						width: 10px;
					}
					td:first-child {
						text-align: right;
					}
					tr.odd {
						background-color: none;
					}
					tr.even {
						background-color: rgba(0, 0, 0, 0.05);
					}
				}
			}
			/*td {
				width: 200px;
				height: 200px;
			}*/
		}
		tr > td {
			vertical-align: top;
		}

		img {
			width: auto;
		}
	}
}




					.pretty {
						width: 100%;

						//border-collapse: separate;
						//border-spacing: 8px 0px;

						//margin: 0px -8px;

						td, th {
							padding: 0px 6px;
						}

						/*tr.odd {
							background-color: rgba(255, 255, 255, 0.45);
						}
						tr.even {

						}*/

						thead {
							th {
								font-weight: bold;
								border-bottom: 1px solid #e0e0e0;
								margin-bottom: @magicNo;
								//border-spacing: 8px 0px 8px 0px;
							}
						}

						tbody {
							tr.odd, tr:nth-child(odd) {
								background-color: rgba(255, 255, 255, 0.45);
								border-bottom: 1px solid rgba(255, 255, 255, 0.45);
							}
							tr.even, tr:nth-child(even) {
								border-bottom: 1px solid #E5EFE5;
							}
							
							tr {
								&:hover {
									border-bottom: 1px solid #e0e0e0;
								}

								/*td:first-child {
									letter-spacing: 0px;
								}
								td:last-child {
									letter-spacing: 1px;
								}

								td.quantity {
									color: #888888;
									text-align: right;
								}*/
							}
						}
					}


	.cart-log {
		max-height: 300px;
		overflow-y: auto;
		padding: 8px 8px;
		//background: rgba(0,0,0, 0.05);
		//border: 1px solid #ffffff;
		background: rgba(255, 255, 255, 0.45);

		.cart-log-entry {
			font-size: 0.9em;
			line-height: 1.2em;

			.datetime {
				float: right;
				padding-left: 16px;
				font-size: 0.9em;
				line-height: 1.2em;
				opacity: 0.5;
			}
			.counter {
				opacity: 0.5;
			}
		}
	}

	.cart-log-add-comment {
		textarea {
			width: 720px - 2*6px;
			height: 160px;
			margin-bottom: 10px !important;
		}

		img {
			border: none !important;
			box-shadow: none !important;
			float: none !important;
			margin-top: 0px !important;
		}
	}


.components, #site #main #content .page-kit .components {
					table {
						.pretty;

						//border-collapse: separate;
						//border-spacing: 8px 0px;

						//margin: 0px -8px;

						tbody {
							tr {
								&.anchor:target {
									//transition: background-color 0.8s ease-out;
									background: rgba(255,255,0, 0.4);
								}

								td:first-child {
									letter-spacing: 0px;
								}
								td:nth-child(4) {
									letter-spacing: 1px;
								}

								td.quantity {
									color: #888888;
									text-align: right;
								}

								td.component-cart-actions, td.kit-cart-actions {
									text-align: right;

									white-space: nowrap;

									img {
										box-shadow: #DDDDDD 1px 1px 0px;
										//box-shadow: none;
										float: none;
										margin: 0px 1px;
										margin-right: 4px;
										cursor: pointer;

										vertical-align: -1px;

										transition: opacity 0.5s ease-out;

										&:last-child {
											margin-left: 4px;
											opacity: 0.2;
											cursor: default;
										}

										&.disabled-loading {
											cursor: default;
										}
									}

									div {
										width: 30px;
										display: inline-block;
										text-align: center;
									}

									color: #BBBBBB;

									&.in-cart {
										color: #333333;
										//span {
										//	color: #333333;
										//}

										img:last-child {
											opacity: 1;
											cursor: pointer;
										}
									}
								}


								&.start-kits {
									td {
										padding-top: 16px;
										padding-bottom: 8px;
										font-size: 20px;
										font-weight: bold;
									}
								}

								&.kit {
									font-weight: bold;

									line-height: 2.2em;

background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(246,246,246,1) 47%, rgba(237,237,237,1) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(47%,rgba(246,246,246,1)), color-stop(100%,rgba(237,237,237,1))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%); /* IE10+ */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=0 ); /* IE6-9 */

									img {
										vertical-align: -3px !important;
									}

									.kit-opener {
										display: block;
										float: left;
										width: 16px;
										height: 16px;
										margin: 7px 10px 0px 0px;

										cursor: pointer;

										background: url('../img/icon/modMinus.gif') top left no-repeat;

										&.closed {
											background: url('../img/icon/modPlus.gif') top left no-repeat;
										}
									}
								}
							}


							&.kit-content {

								tr {
									//border-left: 4px solid #999999;
									td:first-child {
										padding-left: 20px;
									}
								}
							}
						}
					}

}

#kit-detailed-modal {
	td.component-cart-actions, td.kit-cart-actions {
		div {
			width: 24px !important;
		}
	}

	td.components .inside {
		//height: 200px;
		height: 100%;
		overflow-y: auto;
	}
}


.info-box {
	padding: 12px 14px;
	box-shadow: 1px 1px 4px #AAAAAA;
	background: rgb(249,249,249); /* Old browsers */
	background: -moz-linear-gradient(top,  rgb(249,249,249) 0%, rgb(237,237,237) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(249,249,249)), color-stop(100%,rgb(237,237,237))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgb(249,249,249) 0%,rgb(237,237,237) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgb(249,249,249) 0%,rgb(237,237,237) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgb(249,249,249) 0%,rgb(237,237,237) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgb(249,249,249) 0%,rgb(237,237,237) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ededed',GradientType=0 ); /* IE6-9 */

	span {
		font-weight: bold;
	}
}


#cart-complete {

	margin-top: @magicNo;

	table tr td:last-child {
		padding-left: @magicNo;

		input {
			width: 280px;
		}
	}

					/*input, textarea {
						width: 220px;
						border: 1px solid #DDDDDD;
						margin: 1px 0px;
						padding: 2px 6px;
						-webkit-border-radius: 4px;
						-moz-border-radius: 4px;
						border-radius: 4px;

						font-family: inherit;
						font-size: 1.2em;

						.background-image(~"linear-gradient(180deg, rgba(237,237,237,1) 0%,rgba(246,246,246,1) 53%,rgba(255,255,255,1) 100%)");
					}

					input[type=submit], input[type=button] {
						margin-top: @magicNo * 2;
						width: 234px;
						font-weight: bold;

						color: #333833;

						.background-image(~"linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(246,246,246,1) 47%,rgba(237,237,237,1) 100%)");
					}*/

					textarea {
						width: 450px;
						height: 250px;

						font-size: 0.9em;
					}
				}


/*button img, #site #main #content button img {
	margin: 0px;
	float: none;
	display: inline;
	box-shadow: none;
	vertical-align: middle;
}*/
body, #site #main #content {
	input, textarea, select, button {
		padding: 6px 9px;
		margin: 0px 0px;

		//border: 1px solid #bbccbb;
		border: none;

		//.box-shadow(~"rgba(0,0,0, 0.3) 0px 1px 2px inset");
		.box-shadow(~"rgba(0,0,0, 0.3) 0px 1px 1px inset");
		//border-top: 1px solid rgba(0,0,0, 0.1);

		.border-radius(3px);

		//background: url('../img/inputbg.png') left top repeat;

		.background-image(~"linear-gradient(180deg, #efefef 0%, #ffffff 80%)");

		font-family: 'Open Sans', Verdana, sans-serif;
		font-weight: normal;
		font-size: 1.125em;
		//color: #445044;
		color: #333833;

		line-height: 1.3em;

		.box-sizing(border-box);

		&.stretch {
			width: 100%;
		}

		// from jquery validation plugin
		&.error {
			//border-bottom: 1px solid #cc0000;
			//.box-shadow(~"rgba(255,0,0, 0.7) 0px 1px 1px inset");
		}

		option {
			padding: 0px 6px;
		}
	}
	input[type="submit"], input[type="button"], input[type="reset"], button {
		//.box-shadow(~"rgba(0,0,0, 0.3) 0px -1px 0px inset");
		//.background-image(~"linear-gradient(0deg, #efefef 0%, #ffffff 80%)");

		text-align: center;
		font-weight: 600;

		background: #047b26;
		color: #f5f5f5;

		img {
			margin: 0px;
			float: none;
			display: inline;
			box-shadow: none;
			vertical-align: 0px;

			&.left {
				margin-right: 6px;
			}
			&.right {
				margin-left: 6px;
			}
		}

		//.background-image(~"linear-gradient(180deg, #05892a 0%, #047b26 100%)");
		.background-image(~"linear-gradient(180deg, rgba(255,255,255, 0.25) 0%, rgba(255,255,255, 0.0) 100%), url('../img/footerbg.png')");
		//background: url('../img/footerbg.png') left top repeat;

		border: none;
		.box-shadow(~"rgba(0,0,0, 1.0) 0px -1px 0px inset");

		text-shadow: rgba(0,0,0, 1.0) 0px -1px 0px;

		&:hover {
			//.background-image(~"linear-gradient(180deg, #05982f 0%, #047b26 100%)");
			.background-image(~"linear-gradient(180deg, rgba(255,255,255, 0.3) 0%, rgba(255,255,255, 0.0) 100%), url('../img/footerbg.png')");
		}
		&:active {
			//background: url('../img/footerbg.png');
			.background-image(~"linear-gradient(180deg, rgba(255,255,255, 0.1) 0%, rgba(255,255,255, 0.0) 100%), url('../img/footerbg.png')");
		}

		padding: 12px 25px;
		&.inline {
			padding: 6px 20px;
		}


		&[disabled] {
			.background-image(~"linear-gradient(180deg, rgba(255,255,255, 0.25) 0%, rgba(255,255,255, 0.0) 100%), url('../img/footerbg.png') !important");
		}


		/*&.light {
			color: #333333;
			text-shadow: rgba(0,0,0, 1.0) 0px -1px 0px;
			.background-image(~"linear-gradient(180deg, rgba(255,255,255, 0.25) 0%, rgba(255,255,255, 0.0) 100%)");

			&:hover {
			//.background-image(~"linear-gradient(180deg, #05982f 0%, #047b26 100%)");
			.background-image(~"linear-gradient(180deg, rgba(255,255,255, 0.3) 0%, rgba(255,255,255, 0.0) 100%), url('../img/footerbg.png')");
			}
			&:active {
				//background: url('../img/footerbg.png');
				.background-image(~"linear-gradient(180deg, rgba(255,255,255, 0.1) 0%, rgba(255,255,255, 0.0) 100%), url('../img/footerbg.png')");
			}

			&[disabled] {
				.background-image(~"linear-gradient(180deg, rgba(255,255,255, 0.25) 0%, rgba(255,255,255, 0.0) 100%), url('../img/footerbg.png') !important");
			}
		}*/
	}
}

form, #site #main #content form {
	.label {
		margin: 0px 0px 1px 0px;
		padding: 0px 1px;

		color: lighten(#333833, 15%);
	}
	.annotation {
		margin: 3px 0px 0px 0px;
		padding: 0px 1px;

		font-size: 0.8em;

		color: lighten(#333833, 25%);
	}
	// from jquery validation plugin
	label.error {
		font-size: 0.8em;
		color: #cc0000;
		display: block;
		margin: 0px;
		padding: 0px 1px;
	}

	input[type="submit"], input[type="button"], input[type="reset"], button {
		//margin-top: 24px;
	}
}

form {
	table {
		margin: 0px;

		tr {
			td {
				vertical-align: top;

				padding: 7px 12px;

				&:first-child {
					padding-left: 0px;
				}
				&:last-child {
					padding-right: 0px;
				}

				input, textarea, select, button {
					width: 100%;

					.inline {
						width: auto;
					}
				}
			}

			&.section-head td {
				font-family: Coda, 'Open Sans Condensed', 'Open Sans', Verdana, sans-serif;
				font-weight: normal;
				line-height: 1em;
				font-size: 2em;
				//border-bottom: 1px solid #cccccc;
				//.background-image(~"linear-gradient(0deg, rgba(70,0,0, 0.3) 0px, rgba(70,0,0, 0.0) 4px)");
				border-bottom: 1px solid #484c48;

				padding-top: 24px;
			}
		}
	}
}

#register table {
	width: 580px;
	//width: 100%;
	//margin: 0px auto;

	tr td {
		width: 50%;

		.input-salutation, .input-zip {
			width: 100px;
		}

		input[type="submit"], input[type="button"], button {
			margin-top: @magicNo;
		}
	}
}

form#login, form#registration-link, form#forgotpwd {
	width: 320px;
}
form#registration-link {
	float: right;
	padding-top: 68px;
}



#sessionMessages {
	margin-bottom: @magicNo * 1.5;
}
.statusMessage {
	padding: 14px;
	/*margin: 10px 0px;*/
	margin: 9px 4px 18px 4px;
	background: #F1F1F1;
	color: #444444;
	//font-size: 15px;
	/*'Verdana';*/
	border: 2px solid #DDDDDD;
	line-height: 1.5em;
	text-align: center;
	
	/*-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	border-radius: 8px;*/
	.border-radius(3px);

	img {
		vertical-align: bottom;
		margin: 0px 5px 0px 0px;
	}
	&.error {
		/*background: #ffe2e5;
		border: 2px solid #ffb6bb;*/
		color: #d20039;
		background: #F5F5F5;
		border: 1px solid #ffb6bb;
		outline: 5px solid #ffe2e5;
	}
	&.notice {
		/*background: #dff4ff;
		border: 2px solid #c2e1ef;*/
		color: #336699;
		
		background: #F5F5F5;
		border: 1px solid #c2e1ef;
		outline: 5px solid #dff4ff;
	}
	&.ok {
		/*background: #E6EFC2;*/
		/*border: 2px solid #C6D880;*/
		color: #82B41A;
		background: #F5F5F5;
		border: 1px solid #C6D880;
		outline: 5px solid #E6EFC2;
	}
}


.signature {
	font-family: 'Herr Von Muellerhoff', cursive;
	font-size: 72px;
	color: #182e7e;

	line-height: 96px;
}


#main .account-overview {
	span {
		font-weight: normal;
		color: #888888;
		margin-right: 0px;
		text-decoration: none;
		font-size: 0.9em;
		vertical-align: baseline;
	}

	ul {
		list-style: square inside;
		margin: 0px 0px 1em 0px;
		padding: 0px;

		li {
			margin-left: 0.5em;
			font-weight: bold;
		}

		&.tractor-list {
			li {
				&:hover img {
					visibility: visible;
				}

				img {
					visibility: hidden;
					float: none;
					display: inline;
					box-shadow: none;
					margin: 0px 0px 0px 5px;
					cursor: pointer;
					vertical-align: middle;
				}
			}
		}
	}

	h3 {
		margin-top: 1em;
	}

	section {
		margin-bottom: 2em;
	}
}